import { FC, useRef } from 'react'
import { useBoolean, useClickAway } from 'ahooks'

import { Button } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

import useAutoTransition from '$browser/useAutoTransition'

import AccountSwitcher from '$blocks/AccountSwitcher'
import Link from '$elements/Link'

import {
  button,
  canvas,
  figure,
  img,
  switcher,
  switcherContainer,
  switcherContent
} from './styles.css'
import { Icon } from '@genie-fintech/ui/icons'

type NavProps = {
  user: {
    loading?: boolean
    photo?: string
    name?: string
  }
}

export const Nav: FC<NavProps> = ({ user }) => {
  const [switcherContainerRef] = useAutoTransition()

  const buttonRef = useRef(null)
  const switcherRef = useRef(null)

  const [menuOpen, { toggle, setFalse }] = useBoolean()

  useClickAway(setFalse, [switcherRef, buttonRef])

  return (
    <nav>
      <div ref={switcherContainerRef} className={switcherContainer}>
        <button
          ref={buttonRef}
          type="submit"
          name="account-switcher-toggle"
          className={button}
          onClick={toggle}
          data-loading={trueOrUndefined(user.loading)}
        >
          <figure className={figure} title={user.name}>
            {user && <img className={img} src={user.photo} />}
            <span className={canvas} />
          </figure>

          <Icon namespace="Down" color="absolute.light" />
        </button>

        {menuOpen && (
          <div className={switcher} ref={switcherRef}>
            <div className={switcherContent}>
              <AccountSwitcher />

              <Link to="authLogin">
                <Button
                  name="use-another-account"
                  type="button"
                  styleVariants={{ size: 'small', type: 'text' }}
                >
                  use another account
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Nav
