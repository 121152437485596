import { FC } from 'react'

import { Spinner } from '@genie-fintech/ui/components'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

import useAutoTransition from '$browser/useAutoTransition'
import useSignal from '$actions/useSignal'

import AccountPreview from '$elements/AccountPreview'

import { updateCurrent } from '$store/session'
import { profile as profileStore } from '$store/profile'
import { profiles } from '$store/profiles'

import { container, headerLoader, listLoader, main, title } from './styles.css'

export const AccountSwitcher: FC<TagsNoRef<'section'>> = containerProps => {
  const [headerRef] = useAutoTransition()
  const [mainRef] = useAutoTransition()

  const profile = useSignal(profileStore)
  const { data: profileList } = useSignal(profiles)

  const switchableProfiles = profileList.filter(({ id }) => id !== profile?.id)

  const profileLoading = profile?.loading
  const currentEmail = profile?.data?.email

  return (
    <section {...propsWithClassNames(containerProps, container)}>
      <header ref={headerRef}>
        {profileLoading && (
          <div className={headerLoader}>
            <Spinner />
          </div>
        )}

        {!profileLoading && <h3 className={title}>{currentEmail}</h3>}
      </header>

      <main ref={mainRef} className={main}>
        {switchableProfiles.map(({ id, data, loading }) => (
          <article key={id}>
            {loading && (
              <div className={listLoader}>
                <Spinner />
              </div>
            )}

            {!loading && data && (
              <AccountPreview
                name={data.name}
                username={data.email}
                image={data.profile_photo_url}
                containerProps={{
                  onClick: () => updateCurrent(id),
                  'data-testid': `account-id:${data.id} email:${data.email}`
                }}
              />
            )}
          </article>
        ))}
      </main>
    </section>
  )
}

export default AccountSwitcher
